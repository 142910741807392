import * as React from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'
import { hasValue } from '@digital-magic/ts-common-utils'
import { ButtonProps as MuiButtonProps, Button as MuiButton } from '@mui/material'

export type RouterButtonProps = MuiButtonProps & {
  to?: LinkProps['to'] & { state?: unknown }
}

export const RouterButton: React.FC<RouterButtonProps> = ({ to, onClick, children, ...props }) => {
  const navigate = useNavigate()

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (hasValue(to)) {
      if (typeof to === 'string') {
        navigate(to)
      } else {
        const { state, ...restTo } = to

        navigate(restTo, { state })
      }
    }
    if (hasValue(onClick)) {
      onClick(e)
    }
  }

  return (
    <MuiButton {...props} onClick={onClickHandler}>
      {children}
    </MuiButton>
  )
}
